import { ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import Routers from "./Routers/Routers";

const theme = createTheme({
    typography: {
        fontFamily: ["Prompt", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#008CFB",
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App home-base-bg">
                <Routers />
            </div>
        </ThemeProvider>
    );
}

export default App;
