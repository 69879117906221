import React, { useState } from "react";

const OtpInput = ({ length, onComplete }) => {
    const [otp, setOtp] = useState(Array(length).fill(""));

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (!isNaN(value) && value.length === 1) {
            const newOtp = otp.map((digit, i) => (i === index ? value : digit));
            setOtp(newOtp);
            const isComplete = newOtp.every((digit) => digit !== "");
            if (isComplete) {
                onComplete(newOtp.join(""));
            }
            // Move to the next input field
            if (index < length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyUp = (e, index) => {
        if (e.key === "Backspace" && index > 0 && otp[index] === "") {
            // Move to the previous input field on Backspace
            document.getElementById(`otp-input-${index - 1}`).focus();
            onComplete("");
        } else if (e.key === "Backspace" && index >= 0 && otp[index]) {
            let newListOtp = [...otp]; // Create a new array using spread operator
            newListOtp[index] = "";
            setOtp(newListOtp);
            onComplete("");
        } else if (e.key !== "Backspace" && !isNaN(e.key) && otp[index] !== "") {
            let newListOtp = [...otp];
            newListOtp[index] = e.key;
            if (index < length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
            setOtp(newListOtp);
        }
    };

    return (
        <div id="otp-container">
            {otp.map((digit, index) => (
                <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyUp={(e) => handleKeyUp(e, index)}
                    // disabled={digit !== "" && digit !== "" && index !== 5}
                />
            ))}
        </div>
    );
};

export default OtpInput;
