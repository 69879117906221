import { Container } from "@mui/material";
import React, { Component } from "react";
import { Outlet } from "react-router-dom";

export class LayoutUser extends Component {
    render() {
        return (
            <Container maxWidth="md" style={{ paddingTop: 60 }} sx={{ minHeight: "100%" }}>
                <Outlet />
            </Container>
        );
    }
}

export default LayoutUser;
