import React, { Component, createRef } from "react";
import LandscapeWarning from "../Components/Layouts/LandscapeWarning";
import landscape from "../../Assets/icon/image-landscape.png";
import OtpInput from "../Components/Input/OtpInput";
import lotto from "../../Assets/image/background-lotto.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import { toPng } from "html-to-image";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            loading: false,
        };
        this.gridRef = createRef();
    }

    handleOtpComplete = (value) => {
        this.setState({ otp: value });
    };

    onClickDownload = () => {
        if (this.state.otp.length === 6) {
            const targetElement = this.gridRef.current;
            this.setState({ loading: true });
            toPng(targetElement)
                .then((dataUrl) => {
                    this.setState({ loading: false });
                    const downloadLink = document.createElement("a");
                    downloadLink.href = dataUrl;
                    downloadLink.download = `lotto_${this.state.otp}.png`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    alert("Error generating image:", error);
                });
        } else {
            alert("กรุณากรอกให้ครบ 6 ตัวเลข");
        }
    };

    render() {
        const { loading } = this.state;
        return (
            <>
                <Grid display={{ xs: "block", sm: "none" }}>
                    <div>
                        <div style={{ background: "#fff", padding: "10px", textAlign: "center", borderRadius: "20px" }}>
                            โปรดใช้งานในแนวนอน
                            <img alt="image-landscape" src={landscape} style={{ width: "80%" }} />
                        </div>
                    </div>
                </Grid>
                <Grid
                    display={{ xs: "none", sm: "block" }}
                    container
                    spacing={2}
                    bgcolor={"#FFF"}
                    mt="10"
                    borderRadius={5}
                    px={5}
                    pb={5}
                    minHeight={"85vh"}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">ลาวพัฒนา 6 ตัว</Typography>
                        <div ref={this.gridRef} style={{ height: "300px" }}>
                            <Grid container mt={4} position="relative">
                                <Grid item sx={{ position: "absolute", left: "178px" }} mt={12} ref={this.gridRef}>
                                    <OtpInput length={6} onComplete={this.handleOtpComplete} />
                                </Grid>
                                <img src={lotto} alt="lotto" style={{ width: "500px" }} ref={this.gridRef}></img>
                            </Grid>
                        </div>
                        <Grid item xs={12} display={"flex"} justifyContent={"start"} mt={5}>
                            <Button variant="contained" onClick={this.onClickDownload} sx={{ width: "100px" }}>
                                {loading ? <CircularProgress color="inherit" size={"20px"} /> : <DownloadIcon fontSize="small" />}
                                {loading ? "" : "ดาวน์โหลด"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}
